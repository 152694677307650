import { Helmet } from 'react-helmet-async';
// sections
import { ScreeningListView } from 'src/sections/emr/view';

// ----------------------------------------------------------------------

export default function ScreeningInProgressListPage() {
  return (
    <>
      <Helmet>
        <title>Screening In Progress List | Skymer Care</title>
      </Helmet>

      <ScreeningListView type="ScreeningInProgress" screeningTitle="Screening In Progress" />
    </>
  );
}
