import useSWR, { mutate } from 'swr';
import { useMemo } from 'react';
// utils
import axios, { endpoints, fetcher } from 'src/utils/axios';

// ----------------------------------------------------------------------

export async function createPatient(post_data) {
  const response = await axios.post(endpoints.patient.create, post_data);
  return response.data;
}

// ----------------------------------------------------------------------

export async function quickUpdatePatient(post_data) {
  const response = await axios.post(endpoints.patient.quick_update, post_data);
  return response.data;
}

// ----------------------------------------------------------------------

export async function searchPatients(post_data) {
  const response = await axios.post(endpoints.patient.search, post_data);
  return response.data;
}

// ----------------------------------------------------------------------

export async function getPatientUuid(post_data) {
  const response = await axios.post(endpoints.patient.uuid, post_data);
  return response.data;
}

// ----------------------------------------------------------------------

export async function getDepartmentPatientUuid(post_data) {
  const response = await axios.post(endpoints.patient.projection_patient_uuid, post_data);
  return response.data;
}

// ----------------------------------------------------------------------

export async function getDepartmentNewPatient(post_data) {
  const response = await axios.post(endpoints.patient.projection_new_patient, post_data);
  return response.data;
}

// ----------------------------------------------------------------------

export function useGetPatients() {
  const URL = endpoints.patient.list;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      patients: data?.patients || [],
      patientsLoading: isLoading,
      patientsError: error,
      patientsValidating: isValidating,
      patientsEmpty: !isLoading && !data?.patients?.length,
    }),
    [data?.patients, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetPatient(patientUuid) {
  const URL = patientUuid ? [endpoints.patient.details, { params: { patientUuid } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      patient: data?.patient,
      doctors: data?.doctors,
      patientLoading: isLoading,
      patientError: error,
      patientValidating: isValidating,
    }),
    [data?.patient, data?.doctors, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetPatientVisit(patientVisitUuid) {
  const URL = patientVisitUuid
    ? [endpoints.patient.get_visit, { params: { patientVisitUuid } }]
    : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      visit: data?.visit,
      visitLoading: isLoading,
      visitError: error,
      visitValidating: isValidating,
    }),
    [data?.visit, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetYears() {
  const URL = endpoints.patient.years;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      years: data?.years || [],
      yearsLoading: isLoading,
      yearsError: error,
      yearsValidating: isValidating,
      yearsEmpty: !isLoading && !data?.years?.length,
    }),
    [data?.years, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useSearchPatients(query) {
  const URL = query ? [endpoints.product.search, { params: { query } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.results || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
      searchEmpty: !isLoading && !data?.results.length,
    }),
    [data?.results, error, isLoading, isValidating]
  );

  return memoizedValue;
}
