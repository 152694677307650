import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { useState, useCallback, useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
// _mock
// import { _userList, _roles, USER_STATUS_OPTIONS } from 'src/_mock';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
  TableSkeleton,
} from 'src/components/table';
// api
import { useGetScreening } from 'src/api/emr';
//
import { useSnackbar } from 'src/components/snackbar';
import ScreeningTableRow from '../screening-table-row';
import ScreeningTableToolbar from '../screening-table-toolbar';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'sl_no', label: '#' },
  { id: 'mr_no', label: 'MR No.' },
  { id: 'patient_name', label: 'Patient Name' },
  { id: 'gender_age', label: 'Gender and Age' },
  { id: 'address', label: 'Address' },
  { id: 'visit_type', label: 'Visit Type' },
  // { id: 'payment', label: 'Payment', width: 180 },
  { id: '', width: 88 },
];

const defaultFilters = {
  patient_name: '',
};

// ----------------------------------------------------------------------

export default function ScreeningListView({ type, screeningTitle }) {
  const table = useTable();
  const { enqueueSnackbar } = useSnackbar();
  const settings = useSettingsContext();

  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState(defaultFilters);

  const { screening, screeningLoading, screeningEmpty } = useGetScreening(type);

  // console.log('screening', tableData);
  useEffect(() => {
    if (screening.length) {
      setTableData(screening);
    }
  }, [screening]);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  // const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;
  const notFound = (!dataFiltered.length && canReset) || screeningEmpty;

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const colorMap = {
    AwaitingScreening: 'error',
    ScreeningInProgress: 'warning',
    ScreeningCompleted: 'success',
  };

  const color = colorMap[type] || '';

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={`${screeningTitle} List`}
        links={[{ name: 'Dashboard', href: paths.dashboard.root }, { name: screeningTitle }]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <Card>
        <ScreeningTableToolbar filters={filters} onFilters={handleFilters} />

        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={table.selected.length}
                onSort={table.onSort}
              />

              <TableBody>
                {screeningLoading ? (
                  [...Array(table.rowsPerPage)].map((i, index) => (
                    <TableSkeleton key={index} sx={{ height: denseHeight }} />
                  ))
                ) : (
                  <>
                    {dataFiltered
                      .slice(
                        table.page * table.rowsPerPage,
                        table.page * table.rowsPerPage + table.rowsPerPage
                      )
                      .map((row, index) => (
                        <ScreeningTableRow
                          key={index}
                          index={index + 1}
                          row={row}
                          color={color}
                          selected={table.selected.includes(row.id)}
                          onSelectRow={() => table.onSelectRow(row.id)}
                        />
                      ))}
                  </>
                )}
                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                />
                {dataFiltered.length === 0 ? <TableNoData notFound={notFound} /> : ''}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={dataFiltered.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          //
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>
    </Container>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters }) {
  const { patient_name } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (patient_name) {
    inputData = inputData.filter(
      (patient) => patient.patient_name.toLowerCase().indexOf(patient_name.toLowerCase()) !== -1
    );
  }
  return inputData;
}

ScreeningListView.propTypes = {
  type: PropTypes.string,
  screeningTitle: PropTypes.string,
};
