import PropTypes from 'prop-types';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// _mock
import { _invoices } from 'src/_mock';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import BarcodeScannerForm from '../barcode-scanner-form';

// ----------------------------------------------------------------------

export default function BarcodeScannerView() {
  const settings = useSettingsContext();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Barcode Scanner"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },
          { name: 'Barcode Scanner' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <BarcodeScannerForm />
    </Container>
  );
}
