import { Helmet } from 'react-helmet-async';
// routes
import { useParams } from 'src/routes/hooks';
// sections
import { BarcodeScannerView } from 'src/sections/scanner/view';

// ----------------------------------------------------------------------

export default function Barcode() {
  return (
    <>
      <Helmet>
        <title> Barcode Scanner</title>
      </Helmet>

      <BarcodeScannerView />
    </>
  );
}
