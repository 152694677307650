import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
// api
import { reportOpRegistration } from 'src/api/report';
// utils
import { fData } from 'src/utils/format-number';
// routes
import { paths } from 'src/routes/paths';
import { useParams, useRouter } from 'src/routes/hooks';
// assets
import { countries } from 'src/assets/data';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSwitch,
  RHFTextField,
  RHFSelect,
  RHFAutocomplete,
} from 'src/components/hook-form';
import { LoadingScreen } from 'src/components/loading-screen';
import Scrollbar from 'src/components/scrollbar';
import { useBoolean } from 'src/hooks/use-boolean';
import { fDate } from 'src/utils/format-time';
import ReportFilter from './common/report-filter';
import OpTransactionReportDetails from './op-transaction-report-details';

// ----------------------------------------------------------------------

export default function OpTransactionReportForm({ currentUser }) {
  const flgReport = useBoolean();
  const flgDepartment = useBoolean();
  const { enqueueSnackbar } = useSnackbar();
  const [reportTitle, setReportTitle] = useState('OP Transaction Report');
  const [reportDepartment, setReportDepartment] = useState();
  const [reportData, setReportData] = useState([]);

  const NewReportSchema = Yup.object().shape({
    date_from: Yup.mixed().nullable().required('From date is required'),
    date_to: Yup.mixed()
      .required('To date is required')
      .test(
        'date-min',
        'To Date must be later than From Date',
        (value, { parent }) => value >= parent.date_from
      ),

    payment_setting: Yup.string().required('Payment Option is required'),
    // department: Yup.string().required('Department is required'),
    visit_type: Yup.string().required('Visit Type is required'),
  });

  const defaultValues = useMemo(
    () => ({
      date_from: new Date(),
      date_to: new Date(),
      payment_setting: 'All',
      selected_departments: ['All'],
      visit_type: 'All',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewReportSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const onSubmit = handleSubmit(async (data) => {
    flgReport.onTrue();
    try {
      setReportTitle(
        `OP Transaction Report from ${fDate(data.date_from, 'dd-MM-yyy')} to ${fDate(
          data.date_to,
          'dd-MM-yyy'
        )}`
      );
      const response = await reportOpRegistration(data);
      let error_message = 'Something went wrong';
      if (response && response.success === true) {
        const { report } = response;
        if (data.selected_departments.length > 1) {
          flgDepartment.onTrue();
        } else {
          flgDepartment.onFalse();
        }
        setReportData(report);
        reset();
        enqueueSnackbar('Report generated successfully');
        flgReport.onTrue();
        // router.push(paths.print.op_bill(visit));
      } else {
        flgReport.onFalse();
        if (response.reason) {
          error_message = response.reason;
          error_message += JSON.stringify(response.message);
        }
        enqueueSnackbar(`Sorry! ${error_message}. Please try again`, { variant: 'error' });
      }
    } catch (error) {
      flgReport.onFalse();
      console.error(error);
    }
  });

  if (flgReport.value) {
    if (reportData.length) {
      return (
        <OpTransactionReportDetails
          reportTitle={reportTitle}
          reportData={reportData}
          flgReport={flgReport}
          flgDepartment={flgDepartment.value}
          reportDepartment={reportDepartment}
        />
      );
    }
    return <LoadingScreen />;
  }
  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Card>
        <ReportFilter isLoading={isSubmitting} />
      </Card>
    </FormProvider>
  );
}

OpTransactionReportForm.propTypes = {
  currentUser: PropTypes.object,
};
