import useSWR, { mutate } from 'swr';
import { useMemo } from 'react';
// utils
import axios, { endpoints, fetcher } from 'src/utils/axios';

// ----------------------------------------------------------------------

export async function transition(post_data) {
  const response = await axios.post(endpoints.workflow.transition, post_data);
  return response.data;
}

// ----------------------------------------------------------------------

/* export async function quickUpdatePatient(post_data) {
  const response = await axios.post(endpoints.patient.quick_update, post_data);
  return response.data;
} */

// ----------------------------------------------------------------------

export function useGetCurrentState(patientVisitId) {
  // const URL = endpoints.workflow.current_state;
  const URL = patientVisitId
    ? [endpoints.workflow.current_state, { params: { patientVisitId } }]
    : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      current_state: data?.current_state || null,
      currentStateLoading: isLoading,
      currentStateError: error,
      currentStateValidating: isValidating,
      currentStateEmpty: !isLoading && !data?.current_state?.length,
    }),
    [data?.current_state, error, isLoading, isValidating]
  );

  return memoizedValue;
}
