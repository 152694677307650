import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
// @mui
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
// api
import { width } from '@mui/system';
import { useEffect, useState } from 'react';
import { useGetPaymentSettings } from 'src/api/payment_settings';
import { useGetDepartments, getDepartmentDoctors } from 'src/api/department';
// components
import { RHFSelect, RHFMultiSelect, RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function ReportFilter({ isSubmitting, setReportDepartment }) {
  const { control, watch } = useFormContext();
  const { paymentSettings, paymentSettingsLoading, paymentSettingsEmpty } = useGetPaymentSettings();
  const { departments, departmentsLoading, departmentsEmpty } = useGetDepartments();

  const [options, setOptions] = useState([]);

  const values = watch();

  useEffect(() => {
    if (departments && departments.length > 0) {
      const transformedDepartments = departments.map((department) => ({
        value: department.department_uuid,
        label: department.department,
      }));

      const allDepartmentsOption = { value: 'All', label: 'All Departments' };
      const updatedOptions = [allDepartmentsOption, ...transformedDepartments];

      setOptions(updatedOptions);
    }
  }, [departments]);

  return (
    <Stack
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ p: 3, bgcolor: 'background.neutral' }}
    >
      <RHFTextField name="date_from" type="date" label="Date from" />
      <RHFTextField name="date_to" type="date" label="Date to" />

      {departmentsLoading ? (
        'Loading...'
      ) : (
        <RHFMultiSelect
          fullWidth
          checkbox
          name="selected_departments"
          label="Departments"
          sx={{ minWidth: '15rem' }}
          options={options}
        />
      )}

      {paymentSettingsLoading ? (
        'Loading...'
      ) : (
        <RHFSelect
          fullWidth
          name="payment_setting"
          label="Payment Options"
          InputLabelProps={{ shrink: true }}
          PaperPropsSx={{ textTransform: 'capitalize' }}
        >
          <MenuItem value="All">All Payment Options</MenuItem>
          {paymentSettings.map((row, index) => (
            <MenuItem key={index} value={row.payment_setting_uuid}>
              {row.setting}
            </MenuItem>
          ))}
        </RHFSelect>
      )}

      <RHFSelect
        fullWidth
        name="visit_type"
        label="Visit Type"
        InputLabelProps={{ shrink: true }}
        PaperPropsSx={{ textTransform: 'capitalize' }}
      >
        <MenuItem value="All">All Visit Types</MenuItem>
        <MenuItem value="new_visit">New Visits</MenuItem>
        <MenuItem value="re_visit">Re-Visits</MenuItem>
      </RHFSelect>

      <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
        Report
      </LoadingButton>
    </Stack>
  );
}

ReportFilter.propTypes = {
  isSubmitting: PropTypes.bool,
  setReportDepartment: PropTypes.func,
};
