import useSWR, { mutate } from 'swr';
import { useMemo } from 'react';
// utils
import axios, { endpoints, fetcher } from 'src/utils/axios';

// ----------------------------------------------------------------------

export async function createScreening(post_data) {
  const response = await axios.post(endpoints.emr.create, post_data);
  return response.data;
}

// ----------------------------------------------------------------------

export function useGetScreening(state) {
  const URL = state ? [endpoints.emr.state_based_patients, { params: { state } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      screening: data?.emr_list || [],
      screeningLoading: isLoading,
      screeningError: error,
      screeningValidating: isValidating,
      screeningEmpty: !isLoading && !data?.emr_list?.length,
    }),
    [data?.emr_list, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetMedicalReport(uuid) {
  const URL = uuid ? [endpoints.emr.record, { params: { uuid } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      record: data?.record || [],
      recordLoading: isLoading,
      recordError: error,
      recordValidating: isValidating,
      recordEmpty: !isLoading && !data?.record?.length,
    }),
    [data?.record, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------
