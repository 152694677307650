import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
// components
import Iconify from 'src/components/iconify';
import SearchNotFound from 'src/components/search-not-found';

// ----------------------------------------------------------------------

export default function ScreeningHeader({ screeningHead }) {
  return (
    <Typography variant="subTitle1" sx={{ color: 'text.primary', mr: 2 }}>
      {screeningHead}
    </Typography>
  );
}

ScreeningHeader.propTypes = {
  screeningHead: PropTypes.string,
};
