import useSWR, { mutate } from 'swr';
import { useMemo } from 'react';
// utils
import axios, { endpoints, fetcher } from 'src/utils/axios';

// ----------------------------------------------------------------------

export async function createDepartment(post_data) {
  const response = await axios.post(endpoints.departments.create, post_data);
  return response.data?.departments || [];
}

// ----------------------------------------------------------------------

export async function getDepartmentDoctors(post_data) {
  const response = await axios.post(endpoints.departments.doctors, post_data);
  return response.data || [];
}

// ----------------------------------------------------------------------

export function useGetDepartmentPatientsCount() {
  const URL = endpoints.departments.patients_count;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      departments: data?.departments || [],
      departmentsLoading: isLoading,
      departmentsError: error,
      departmentsValidating: isValidating,
      departmentsEmpty: !isLoading && !data?.departments.length,
    }),
    [data?.departments, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetDepartments() {
  const URL = endpoints.departments.list;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      departments: data?.departments || [],
      departmentsLoading: isLoading,
      departmentsError: error,
      departmentsValidating: isValidating,
      departmentsEmpty: !isLoading && !data?.departments.length,
    }),
    [data?.departments, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetDepartment(productId) {
  const URL = productId ? [endpoints.product.details, { params: { productId } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      product: data?.product,
      productLoading: isLoading,
      productError: error,
      productValidating: isValidating,
    }),
    [data?.product, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useSearchDepartments(query) {
  const URL = query ? [endpoints.product.search, { params: { query } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.results || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
      searchEmpty: !isLoading && !data?.results.length,
    }),
    [data?.results, error, isLoading, isValidating]
  );

  return memoizedValue;
}
