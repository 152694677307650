import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

export default function BarcodeScanner({ onScan }) {
  const [scannedData, setScannedData] = useState('');

  const getParameterByName = (name, url) => {
    const urlObj = new URL(url);
    return urlObj.searchParams.get(name);
  };

  useEffect(() => {
    const handleKeydown = (event) => {
      // If Enter key is detected, process the scanned data
      if (event.key === 'Enter') {
        let dataToProcess;

        // Check if scannedData is a URL
        try {
          const url = new URL(scannedData);
          // If it is a URL, get the mr_no parameter
          const mrNo = getParameterByName('mr_no', url);
          dataToProcess = mrNo || scannedData; // Use mr_no if exists, otherwise use scannedData
        } catch (error) {
          // If it's not a valid URL, use scannedData directly
          dataToProcess = scannedData;
        }
        onScan(dataToProcess); // Call onScan with the processed data
        setScannedData(''); // Reset scannedData
      } else {
        // Otherwise, accumulate the input data
        setScannedData((prev) => prev + event.key);
      }
    };

    // Attach the keydown listener globally
    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [scannedData, onScan]);

  // return null; // No UI, only listening to key events
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        p: 1,
        m: 1,
        borderRadius: 1,
        height: '100%',
      }}
    >
      <Card variant="outlined" sx={{ p: 2 }}>
        <Box
          component="img"
          alt="logo"
          src="/assets/images/qr_code.gif"
          sx={{ width: 150, height: 150 }}
        />
      </Card>
    </Box>
  );
}

BarcodeScanner.propTypes = {
  onScan: PropTypes.func,
};
