import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Page, View, Text, Image, Document, Font, StyleSheet } from '@react-pdf/renderer';
// utils
import { fDate } from 'src/utils/format-time';
import { fCurrency } from 'src/utils/format-number';
import { Age } from 'src/utils/calculator';

// ----------------------------------------------------------------------

Font.register({
  family: 'Roboto',
  fonts: [{ src: '/fonts/Roboto-Regular.ttf' }, { src: '/fonts/Roboto-Bold.ttf' }],
});

const useStyles = () =>
  useMemo(
    () =>
      StyleSheet.create({
        col4: { width: '25%' },
        col8: { width: '75%' },
        col6: { width: '50%' },
        mb4: { marginBottom: 4 },
        mb8: { marginBottom: 8 },
        mb40: { marginBottom: 40 },
        h3: { fontSize: 16, fontWeight: 700 },
        h4: { fontSize: 13, fontWeight: 700 },
        body1: { fontSize: 10 },
        body2: { fontSize: 9 },
        subtitle1: { fontSize: 10, fontWeight: 700 },
        subtitle2: { fontSize: 9, fontWeight: 700 },
        alignRight: { textAlign: 'right' },
        alignCenter: { textAlign: 'center' },
        alignItemsCenter: { display: 'flex', alignItems: 'center' },
        page: {
          fontSize: 9,
          lineHeight: 1.6,
          fontFamily: 'Roboto',
          backgroundColor: '#FFFFFF',
          textTransform: 'capitalize',
          padding: '40px 24px 40px 24px',
        },
        header: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          height: 50, // Match this with the paddingTop
          backgroundColor: '#f8f8f8',
          textAlign: 'center',
          justifyContent: 'center',
        },
        footer: {
          left: 0,
          right: 0,
          bottom: 0,
          padding: 12,
          margin: 'auto',
          borderTopWidth: 1,
          borderStyle: 'solid',
          position: 'absolute',
          borderColor: '#DFE3E8',
        },
        gridContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        table: {
          display: 'flex',
          width: 'auto',
        },
        tableHead: {
          padding: '4px 0 1px 0',
          flexDirection: 'row',
          borderBottomWidth: 1,
          borderStyle: 'solid',
          borderColor: '#DFE3E8',
          backgroundColor: '#cccccc',
        },
        tableRow: {
          padding: '2px 0',
          flexDirection: 'row',
          textAlign: 'center',
          justifyContent: 'center',
          borderBottomWidth: 1,
          borderStyle: 'solid',
          borderColor: '#DFE3E8',
        },
        noBorder: {
          paddingTop: 8,
          paddingBottom: 0,
          borderBottomWidth: 0,
        },
        tableCell_5: {
          width: '5%',
        },
        tableCell_7: {
          width: '7%',
        },
        tableCell_10: {
          width: '10%',
        },
        tableCell_15: {
          width: '15%',
        },
        tableCell_20: {
          width: '20%',
        },
        tableCell_25: {
          width: '25%',
        },
        tableCell_50: {
          width: '50%',
          paddingRight: 16,
        },
      }),
    []
  );

// ----------------------------------------------------------------------

export default function OpStatisticsReportPDF({ reportData, reportTitle }) {
  const { statisticsReportData, departmentData } = reportData;
  console.log(statisticsReportData);
  const styles = useStyles();

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={{ alignItems: 'center' }}>
          <Text style={[styles.h3, styles.alignItemsCenter]}>
            Sree Narayana Institute of Ayurvedic Studies and Research - Hospital
          </Text>
          <Text> Pangode </Text>
        </View>

        <View style={[styles.gridContainer, styles.mb4]}>
          <View style={styles.col8}>
            <Text style={[styles.subtitle2, styles.mb4]}>{reportTitle}</Text>
          </View>
          <View style={styles.col4}>
            <Text style={[styles.subtitle2, styles.alignRight]}>
              Report Date: {fDate(new Date())}
            </Text>
          </View>
        </View>

        {/* <Text style={[styles.subtitle1, styles.mb8]}>OP Report</Text> */}

        <View style={styles.table}>
          <View style={styles.mb4} fixed>
            <View style={[styles.tableHead]}>
              <View style={[styles.tableCell_7, styles.alignCenter]}>
                <Text style={styles.subtitle2}>Date</Text>
              </View>

              {departmentData.map((dept, index) => (
                <View style={[styles.tableCell_10, styles.alignCenter]} key={index}>
                  <Text style={styles.subtitle2}>{dept}</Text>
                </View>
              ))}

              <View style={[styles.tableCell_5, styles.alignCenter]}>
                <Text style={styles.subtitle2}>Total</Text>
              </View>
            </View>
          </View>

          <View>
            {Object.entries(statisticsReportData).map(([date, row], index) => (
              <View wrap={false} style={styles.tableRow} key={index}>
                <View style={[styles.tableCell_7, styles.alignCenter]}>
                  <Text>{fDate(row.visit_date)}</Text>
                </View>
                {departmentData.map((dept, deptIndex) => (
                  <View key={deptIndex} style={[styles.tableCell_10, styles.alignCenter]}>
                    <Text>{row[dept] || 0}</Text>
                  </View>
                ))}
                <View style={[styles.tableCell_5, styles.alignCenter]}>
                  <Text>{row.Total}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>

        <View style={[styles.gridContainer, styles.footer]} fixed>
          <View style={styles.col8}>
            {/* <Text style={styles.subtitle2}>NOTES</Text> */}
            <Text>This is a system-generated report. No signature required.</Text>
          </View>
          <View style={[styles.col4, styles.alignRight]}>
            {/* <Text style={styles.subtitle2}>Have a Question?</Text> */}
            {/* <Text>support@skymer.in</Text> */}
            <Text
              style={styles.pageNumbers}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
}

OpStatisticsReportPDF.propTypes = {
  reportData: PropTypes.array,
  reportTitle: PropTypes.string,
};
