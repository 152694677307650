import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function ScreeningTableToolbar({ filters, onFilters }) {
  const inputRef = useRef(null);

  const handleFilterName = useCallback(
    (event) => {
      onFilters('patient_name', event.target.value);
    },
    [onFilters]
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        p: 2.5,
        pr: { xs: 2.5, md: 1 },
      }}
    >
      <TextField
        fullWidth
        value={filters.patient_name}
        inputRef={inputRef}
        onChange={handleFilterName}
        placeholder="Name Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}

ScreeningTableToolbar.propTypes = {
  filters: PropTypes.object,
  onFilters: PropTypes.func,
};
