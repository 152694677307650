import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Unstable_Grid2';
import ButtonBase from '@mui/material/ButtonBase';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// api
import { useGetDepartments, getDepartmentDoctors } from 'src/api/department';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
// components
import Scrollbar from 'src/components/scrollbar';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFEditor,
  RHFSwitch,
  RHFTextField,
  RHFSelect,
  RHFRadioGroup,
  RHFAutocomplete,
  RHFMultiCheckbox,
} from 'src/components/hook-form';
//
import {
  APPETITE,
  BOWEL,
  BLADDER,
  SLEEP,
  DIET,
  ADDICTION,
  ALLERGY,
  EXERCISE,
} from 'src/api/Initial_assessment';
import { SelectorSkeleton } from 'src/components/loading-screen/selector-skeleton ';
import { createScreening } from 'src/api/emr';
import { usePatientWorkflow } from 'src/workflows/patient_workflow_provider';

// ----------------------------------------------------------------------

export default function ScreeningInput({
  patient_uuid,
  patient_visit_uuid,
  screening,
  department_uuid,
  doctor_uuid,
  currentDoctors,
}) {
  const router = useRouter();
  const inputRef = useRef(null);
  const { departments, departmentsLoading, departmentsEmpty } = useGetDepartments();
  const [doctors, setDoctors] = useState([]);

  const { state, send, triggerTransition } = usePatientWorkflow();

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const NewScreeningSchema = Yup.object().shape({
    screening_uuid: Yup.string(),
    patient_uuid: Yup.string(),
    patient_visit_uuid: Yup.string(),
    chief_complaints: Yup.string().required('Chief Complaint is required'),
    // illness_history: Yup.string().required('Illness History is required'),
    /* chief_complaints: Yup.string().when('draft', {
      is: false,
      then: (schema) => schema.required('Chief Complaint is required'),
      otherwise: (schema) => schema.nullable(),
    }), */
    illness_history: Yup.string().when('draft', {
      is: false,
      then: (schema) => schema.required('Illness History is required'),
      otherwise: (schema) => schema.nullable(),
    }),
    appetite: Yup.string(),
    appetite_remarks: Yup.string(),
    bowel: Yup.string(),
    bowel_remarks: Yup.string(),
    bladder: Yup.string(),
    bladder_remarks: Yup.string(),
    sleep: Yup.string(),
    sleep_remarks: Yup.string(),
    diet: Yup.string(),
    diet_remarks: Yup.string(),
    addiction: Yup.array(),
    addiction_remarks: Yup.string(),
    allergy: Yup.string(),
    allergy_remarks: Yup.string(),
    exercise: Yup.string(),
    exercise_remarks: Yup.string(),
    height: Yup.string(),
    weight: Yup.string(),
    bmi: Yup.string(),
    department: Yup.string().when('draft', {
      is: false,
      then: (schema) => schema.required('Department is required'),
      otherwise: (schema) => schema.nullable(),
    }),
    doctor: Yup.string().when('draft', {
      is: false,
      then: (schema) => schema.required('Doctor is required'),
      otherwise: (schema) => schema.nullable(),
    }),
    screened_by: Yup.string().when('draft', {
      is: false,
      then: (schema) => schema.required('Screened By is required'),
      otherwise: (schema) => schema.nullable(),
    }),
    // department: Yup.string().required('Department is required'),
    // doctor: Yup.string().required('Doctor is required'),
    // screened_by: Yup.string().required('Screened By is required'),
    draft: Yup.boolean(),
  });

  const defaultValues = useMemo(
    () => ({
      screening_uuid: screening?.screening_uuid || '',
      patient_uuid: screening?.patient_uuid || patient_uuid,
      patient_visit_uuid: screening?.patient_visit_uuid || patient_visit_uuid,
      chief_complaints: screening?.chief_complaints || '',
      illness_history: screening?.illness_history || '',
      appetite: screening?.appetite || '',
      appetite_remarks: screening?.appetite_remarks || '',
      bowel: screening?.bowel || '',
      bowel_remarks: screening?.bowel_remarks || '',
      bladder: screening?.bladder || '',
      bladder_remarks: screening?.bladder_remarks || '',
      sleep: screening?.sleep || '',
      sleep_remarks: screening?.sleep_remarks || '',
      diet: screening?.diet || '',
      diet_remarks: screening?.diet_remarks || '',
      addiction: screening?.addiction || [],
      addiction_remarks: screening?.addiction_remarks || '',
      allergy: screening?.allergy || '',
      allergy_remarks: screening?.allergy_remarks || '',
      exercise: screening?.exercise || '',
      exercise_remarks: screening?.exercise_remarks || '',
      height: screening?.height || '',
      weight: screening?.weight || '',
      bmi: screening?.bmi || '',
      department: department_uuid || '',
      doctor: doctor_uuid || '',
      screened_by: screening?.screened_by || '',
      draft: false,
    }),
    [screening, patient_uuid, patient_visit_uuid, department_uuid, doctor_uuid]
  );

  const methods = useForm({
    resolver: yupResolver(NewScreeningSchema),
    defaultValues,
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (currentDoctors) {
      setDoctors(currentDoctors);
    }
  }, [currentDoctors]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (screening) {
      reset(defaultValues);
    }
  }, [screening, defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    console.log('SAVE', data);
    try {
      const response = await createScreening(data);
      if (response && response.success === true) {
        reset();
        if (data.draft === false) {
          send({ type: 'SCREENING_COMPLETED' });
          triggerTransition();
        }
        enqueueSnackbar(
          data.draft === true ? 'Saved to Draft!' : 'Screening Updated successfully!'
        );
        router.push(paths.emr.screening.awaiting);
      } else {
        enqueueSnackbar('Sorry! Something went wrong. Please try again', { variant: 'error' });
      }
      // router.push(paths.dashboard.user.list);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Sorry! Server Error. Please try again', { variant: 'error' });
    }
  });

  const getDoctors = useCallback(
    async (event) => {
      try {
        const POST = {
          department_uuid: event.target.value,
        };
        const response = await getDepartmentDoctors(POST);
        if (response && response.success === true) {
          const data = response.doctors;
          setDoctors(data);
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Sorry! Something went wrong. Please try again', { variant: 'error' });
      }
    },
    [setDoctors, enqueueSnackbar]
  );

  const renderComplaint = (
    <Grid xs={12} md={12}>
      <Card sx={{ backgroundColor: '#26313e' }}>
        <CardHeader title="Chief Complaints" />
        <Stack spacing={3} sx={{ p: 3 }}>
          <Stack spacing={1.5}>
            <Typography variant="subtitle2">Details about chief complaint with duration</Typography>
            <RHFEditor
              simple
              name="chief_complaints"
              placeholder="Chief Complaints"
              inputRef={inputRef}
            />
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );

  const renderIllnessHistory = (
    <Grid xs={12} md={12}>
      <Card sx={{ backgroundColor: '#26313e' }}>
        <CardHeader title="History of Past Illness" />
        <Stack spacing={3} sx={{ p: 3 }}>
          <Stack spacing={1.5}>
            <Typography variant="subtitle2">
              Small description about past illness history
            </Typography>
            <RHFEditor simple name="illness_history" placeholder="Past Illness" />
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );

  const renderPersonalHistory = (
    <Grid xs={12} md={12}>
      <Card sx={{ backgroundColor: '#26313e' }}>
        <CardHeader title="Personal History" />
        <Stack spacing={3} sx={{ p: 3 }}>
          <Stack spacing={0}>
            <Typography variant="subtitle2">Appetite</Typography>
            <Stack
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
              }}
            >
              <RHFRadioGroup row spacing={4} name="appetite" options={APPETITE} />
              <RHFTextField name="appetite_remarks" placeholder="Appetite_ Remarks" type="text" />
            </Stack>
          </Stack>
          <Stack spacing={0}>
            <Typography variant="subtitle2">Bowel</Typography>
            <Stack
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
              }}
            >
              <RHFRadioGroup row spacing={4} name="bowel" options={BOWEL} />
              <RHFTextField name="bowel_remarks" placeholder="Bowel Remarks" type="text" />
            </Stack>
          </Stack>
          <Stack spacing={0}>
            <Typography variant="subtitle2">Bladder</Typography>
            <Stack
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
              }}
            >
              <RHFRadioGroup row spacing={4} name="bladder" options={BLADDER} />
              <RHFTextField name="bladder_remarks" placeholder="Bladder Remarks" type="text" />
            </Stack>
          </Stack>
          <Stack spacing={0}>
            <Typography variant="subtitle2">Sleep</Typography>
            <Stack
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
              }}
            >
              <RHFRadioGroup row spacing={4} name="sleep" options={SLEEP} />
              <RHFTextField name="sleep_remarks" placeholder="Sleep Remarks" type="text" />
            </Stack>
          </Stack>
          <Stack spacing={0}>
            <Typography variant="subtitle2">Diet</Typography>
            <Stack
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
              }}
            >
              <RHFRadioGroup row spacing={4} name="diet" options={DIET} />
              <RHFTextField name="diet_remarks" placeholder=" Diet Remarks" type="text" />
            </Stack>
          </Stack>
          <Stack spacing={0}>
            <Typography variant="subtitle2">Addiction</Typography>
            <Stack
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
              }}
            >
              <RHFMultiCheckbox row spacing={4} name="addiction" options={ADDICTION} />
              <RHFTextField name="addiction_remarks" placeholder="Addiction Remarks" type="text" />
            </Stack>
          </Stack>
          <Stack spacing={0}>
            <Typography variant="subtitle2">Allergy</Typography>
            <Stack
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
              }}
            >
              <RHFRadioGroup row spacing={4} name="allergy" options={ALLERGY} />
              <RHFTextField name="allergy_remarks" placeholder="Allergy Remarks" type="text" />
            </Stack>
          </Stack>
          <Stack spacing={0}>
            <Typography variant="subtitle2">Exercise</Typography>
            <Stack
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
              }}
            >
              <RHFRadioGroup row spacing={4} name="exercise" options={EXERCISE} />
              <RHFTextField name="exercise_remarks" placeholder="Exercise Remarks" type="text" />
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );

  const renderBmi = (
    <Grid xs={12} md={12}>
      <Card sx={{ backgroundColor: '#26313e' }}>
        <CardHeader title="BMI Calculation" />
        <Stack spacing={3} sx={{ p: 3 }}>
          <Stack spacing={1.5}>
            <Typography variant="subtitle2">
              For men: IBW (kg) = 50 + 2.3 x (height (in) - 60). For women: IBW (kg) = 45.5 + 2.3 x
              (height (in) - 60)
            </Typography>
            <Stack spacing={0}>
              <Stack
                spacing={1.5}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                }}
              >
                <RHFTextField name="height" placeholder="Height" type="number" />
                <RHFTextField name="weight" placeholder="Weight" type="number" />
                <RHFTextField name="bmi" placeholder="BMI" type="number" />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );

  const renderConsultationDetails = (
    <Grid xs={12} md={12}>
      <Card sx={{ backgroundColor: '#26313e' }}>
        <CardHeader title="Consultation Details" />
        <Stack spacing={3} sx={{ p: 3 }}>
          <Stack spacing={1.5}>
            <Typography variant="subtitle2">
              Select appropriat Department and Doctor from the given dropdown list{' '}
            </Typography>
            <Stack spacing={0}>
              <Stack
                spacing={1.5}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                }}
              >
                {departmentsLoading ? (
                  <SelectorSkeleton />
                ) : (
                  <Controller
                    name="department"
                    control={control}
                    render={({ field }) => (
                      <RHFSelect
                        name="department"
                        label="Departments"
                        // value={field.value || ''}
                        onChange={(event) => {
                          field.onChange(event); // Native onChange from RHF
                          getDoctors(event); // Custom function
                        }}
                      >
                        {departments.map((row, index) => (
                          <MenuItem key={index} value={row.department_uuid}>
                            {row.department}
                          </MenuItem>
                        ))}
                      </RHFSelect>
                    )}
                  />
                )}

                {!doctors.length ? (
                  <SelectorSkeleton />
                ) : (
                  <RHFSelect name="doctor" label="Doctors">
                    {doctors.map((row, index) => (
                      <MenuItem key={index} value={row.doctor_uuid}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                )}

                <RHFTextField name="screened_by" placeholder="Screened By" type="text" />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <FormControlLabel
          control={<RHFSwitch name="draft" />}
          name="draft"
          label="Save as Draft"
          sx={{ flexGrow: 1, pl: 3 }}
        />

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          Update Initial Assessment
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <Scrollbar sx={{ px: 3, py: 5, height: 1 }}>
      <Box>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Grid container spacing={3}>
            {renderComplaint}
            {renderIllnessHistory}
            {renderPersonalHistory}
            {renderBmi}
            {renderConsultationDetails}

            {renderActions}
          </Grid>
        </FormProvider>
      </Box>
    </Scrollbar>
  );
}

ScreeningInput.propTypes = {
  patient_uuid: PropTypes.string,
  patient_visit_uuid: PropTypes.string,
  screening: PropTypes.object,
  department_uuid: PropTypes.string,
  doctor_uuid: PropTypes.string,
  currentDoctors: PropTypes.array,
};
