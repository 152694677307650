import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ListItemText from '@mui/material/ListItemText';
// hooks
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
// components
import Label from 'src/components/label';
//
import { Age } from 'src/utils/calculator';
import { fDate } from 'src/utils/format-time';

// ----------------------------------------------------------------------

export default function ScreeningTableRow({ row, color, index, selected, onSelectRow }) {
  const {
    medical_record_no,
    patient_name,
    gender,
    date_of_birth,
    house_name,
    visit_type,
    patient_uuid,
    patient_visit_uuid,
  } = row;

  // const { department_name, doctor_name, visit_type, payment } = last_visit;
  const router = useRouter();

  return (
    <TableRow
      hover
      key={patient_visit_uuid}
      selected={selected}
      sx={{ borderBottom: (theme) => `solid 1px ${theme.palette.divider}` }}
    >
      {/* <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell> */}

      <TableCell sx={{ whiteSpace: 'nowrap' }}>{index}</TableCell>

      <TableCell>
        <ListItemText primary={medical_record_no} />
      </TableCell>

      <TableCell>
        <ListItemText primary={patient_name} />
      </TableCell>

      <TableCell>
        <ListItemText primary={`${gender} | ${Age(date_of_birth)}`} />
      </TableCell>

      <TableCell>
        <ListItemText primary={house_name} />
      </TableCell>

      <TableCell>
        {visit_type ? (
          <Label
            variant="soft"
            color={
              (visit_type === 'new-visit' && 'primary') ||
              (visit_type === 're-visit' && 'success') ||
              'default'
            }
          >
            {visit_type}
          </Label>
        ) : (
          ''
        )}
      </TableCell>

      <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <Button
          color={color}
          sx={{ color: '#000000' }}
          variant="contained"
          size="small"
          onClick={() => router.push(paths.emr.record(patient_visit_uuid))}
        >
          Open
        </Button>
      </TableCell>
    </TableRow>
  );
}

ScreeningTableRow.propTypes = {
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  color: PropTypes.string,
  index: PropTypes.number,
  selected: PropTypes.bool,
};
