import { Helmet } from 'react-helmet-async';
// routes
import { useParams } from 'src/routes/hooks';
// sections
import { OpStatisticsReportView } from 'src/sections/report/reception/view';

// ----------------------------------------------------------------------

export default function OpStatisticsReportPage() {
  // const params = useParams();

  // const { uuid } = params;

  return (
    <>
      <Helmet>
        <title>Op Statistics Report | Skymer Care</title>
      </Helmet>

      <OpStatisticsReportView />
    </>
  );
}
