import { Helmet } from 'react-helmet-async';
// sections
import { ScreeningListView } from 'src/sections/emr/view';

// ----------------------------------------------------------------------

export default function ScreeningCompletedListPage() {
  return (
    <>
      <Helmet>
        <title>Screening Completed List | Skymer Care</title>
      </Helmet>

      <ScreeningListView type="ScreeningCompleted" screeningTitle="Screening Completed" />
    </>
  );
}
