import { Helmet } from 'react-helmet-async';
// sections
import { ScreeningListView } from 'src/sections/emr/view';

// ----------------------------------------------------------------------

export default function AwaitingScreeningListPage() {
  return (
    <>
      <Helmet>
        <title>Awaiting Screening List | Skymer Care</title>
      </Helmet>

      <ScreeningListView type="AwaitingScreening" screeningTitle="Awaiting Screening" />
    </>
  );
}
