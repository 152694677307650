export function Age(dob) {
  const dobDate = new Date(dob);
  const today = new Date();

  let yearDiff = today.getFullYear() - dobDate.getFullYear();
  let monthDiff = today.getMonth() - dobDate.getMonth();
  let dayDiff = today.getDate() - dobDate.getDate();

  if (dayDiff < 0) {
    const tempDate = new Date(today.getFullYear(), today.getMonth(), 0);
    dayDiff += tempDate.getDate();
    monthDiff -= 1;
  }

  if (monthDiff < 0) {
    yearDiff -= 1;
    monthDiff += 12;
  }

  const ageArray = [];

  if (yearDiff > 0) {
    ageArray.push(`${yearDiff} ${yearDiff === 1 ? 'yr' : 'yrs'}`);
  }

  if (yearDiff > 2) {
    return ageArray;
  }

  if (monthDiff > 0) {
    ageArray.push(`${monthDiff} ${monthDiff === 1 ? 'mo' : 'mos'}`);
  }

  if (dayDiff > 0) {
    ageArray.push(`${dayDiff} ${dayDiff === 1 ? 'day' : 'days'}`);
  }

  return ageArray?.length > 0 ? ageArray.join(', ') : '0 days';
}
