import React, { createContext, useContext, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMachine } from '@xstate/react';
import { createMachine } from 'xstate';
import { transition, useGetCurrentState } from 'src/api/workflow';

const patientStateMachine = createMachine({
  id: 'patientWorkflow',
  initial: 'AtReception',
  states: {
    AtReception: {
      on: {
        TO_PAYMENT: 'VisitPaymentRequired',
        TO_SCREENING: 'AwaitingScreening',
        TO_CONSULTATION: 'AwaitingConsultation',
        SCREENING_IN_PROGRESS: 'ScreeningInProgress',
      },
    },
    VisitPaymentRequired: {
      on: { TO_SCREENING: 'AwaitingScreening', TO_CONSULTATION: 'AwaitingConsultation' },
    },
    AwaitingScreening: { on: { SCREENING_IN_PROGRESS: 'ScreeningInProgress' } },
    ScreeningInProgress: { on: { SCREENING_COMPLETED: 'ScreeningCompleted' } },
    ScreeningCompleted: { on: { FINALIZE: 'TreatmentCompleted' } },
    AwaitingConsultation: { on: { NEXT: 'TreatmentCompleted' } },
    TreatmentCompleted: { type: 'final' },
  },
});

const stateToEventMap = {
  AtReception: 'AT_RECEPTION',
  AwaitingScreening: 'TO_SCREENING',
  ScreeningInProgress: 'SCREENING_IN_PROGRESS',
  ScreeningCompleted: 'SCREENING_COMPLETED',
  TreatmentCompleted: 'FINALIZE',
};

const PatientWorkflowContext = createContext();

export function PatientWorkflowProvider({ patientVisitId, children }) {
  const [state, send] = useMachine(patientStateMachine);
  const { current_state } = useGetCurrentState(patientVisitId);

  // Sync initial state with backend's current state
  useEffect(() => {
    if (current_state && state.value !== current_state) {
      const eventType = stateToEventMap[current_state];
      if (eventType) send({ type: eventType });
    }
  }, [current_state, send, state]);

  // Define the triggerTransition function for manual API sync
  const triggerTransition = useCallback(async () => {
    const newState = state.value;

    if (current_state !== newState && newState !== 'AtReception') {
      try {
        const response = await transition({
          patient_visit_uuid: patientVisitId,
          new_state: newState,
        });
        if (response?.success) {
          console.log('State updated:', response.data);
        } else {
          console.error('Error updating state:', response);
        }
      } catch (error) {
        console.error('Error updating state:', error);
      }
    }
  }, [state.value, current_state, patientVisitId]);

  // Memoize the context value to avoid unnecessary re-renders
  const contextValue = useMemo(
    () => ({ state, send, triggerTransition }),
    [state, send, triggerTransition]
  );

  return (
    <PatientWorkflowContext.Provider value={contextValue}>
      {children}
    </PatientWorkflowContext.Provider>
  );
}

PatientWorkflowProvider.propTypes = {
  patientVisitId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export function usePatientWorkflow() {
  return useContext(PatientWorkflowContext);
}
