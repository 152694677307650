import PropTypes from 'prop-types';
import { getPatientUuid } from 'src/api/patient';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useSnackbar } from 'src/components/snackbar';

import BarcodeScanner from 'src/components/barcode-scanner';
//

// ----------------------------------------------------------------------

export default function BarcodeScannerForm() {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const onScan = async (code) => {
    console.log(code);
    try {
      const postData = {
        medical_record_no: code,
      };
      const response = await getPatientUuid(postData);

      if (response && response.success === true) {
        const uuid = response.patient_uuid;
        if (uuid) {
          router.push(paths.patients.patient_visit(uuid));
        } else {
          enqueueSnackbar('Sorry! Something went wrong. Please try again', { variant: 'error' });
        }
      } else {
        enqueueSnackbar('The Patient is not Found. Please try again', { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Sorry! Something went wrong. Please try again', { variant: 'error' });
    }
  };

  return <BarcodeScanner onScan={onScan} />;
}
