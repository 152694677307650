/* export const DUTIES = [
  'Monday to Friday',
  'Weekend availability',
  'Day shift',
]; */

export const APPETITE = [
  { value: 'Good', label: 'Good' },
  { value: 'Poor', label: 'Poor' },
  { value: 'Reduced', label: 'Reduced' },
];

export const BOWEL = [
  { value: 'Regular', label: 'Regular' },
  { value: 'Irregular', label: 'Irregular' },
  { value: 'constipated', label: 'constipated' },
];

export const BLADDER = [
  { value: 'Normal', label: 'Normal' },
  { value: 'Increased', label: 'Increased' },
  { value: 'Decreased', label: 'Decreased' },
];

export const SLEEP = [
  { value: 'Sound', label: 'Sound' },
  { value: 'Reduced', label: 'Reduced' },
  { value: 'Improper', label: 'Improper' },
];

export const DIET = [
  { value: 'Vegetarian', label: 'Vegetarian' },
  { value: 'Non-Vegetarian', label: 'Non-Vegetarian' },
  { value: 'Eggetarian', label: 'Eggetarian' },
];

export const ADDICTION = [
  { value: 'Tea', label: 'Tea' },
  { value: 'Coffee', label: 'Coffee' },
  { value: 'Alcohol', label: 'Alcohol' },
  { value: 'Smoking', label: 'Smoking' },
];

export const ALLERGY = [
  { value: 'Yes', label: 'Yes' },
  { value: 'Not yet detected', label: 'Not yet detected' },
];

export const EXERCISE = [
  { value: 'Regular', label: 'Regular' },
  { value: 'Moderate', label: 'Moderate' },
  { value: 'Minimal', label: 'Minimal' },
];

export const NADI = [
  { value: 'Sadharanam', label: 'Sadharanam' },
  { value: 'Drutham', label: 'Drutham' },
  { value: 'Mandam', label: 'Mandam' },
];

export const MOOTHRAM = [
  { value: 'Avilam', label: 'Avilam' },
  { value: 'Acham', label: 'Acham' },
];

export const MALAM = [
  { value: 'Baddham', label: 'Baddham' },
  { value: 'Abaddham', label: 'Abaddham' },
];

export const JIHWA = [
  { value: 'Liptham', label: 'Liptham' },
  { value: 'Aliptham', label: 'Aliptham' },
];

export const SABDA = [
  { value: 'Spashtam', label: 'Spashtam' },
  { value: 'Heenam', label: 'Heenam' },
];

export const SPARSHA = [
  { value: 'Sheetam', label: 'Sheetam' },
  { value: 'Ushnam', label: 'Ushnam' },
  { value: 'Ardram', label: 'Ardram' },
];

export const DRIK = [
  { value: 'Vektham', label: 'Vektham' },
  { value: 'Avyektham', label: 'Avyektham' },
];

export const AKRITHI = [
  { value: 'Sthoolam', label: 'Sthoolam' },
  { value: 'Madhyamam', label: 'Madhyamam' },
  { value: 'Krisham', label: 'Krisham' },
];

export const DOSHAM = [
  { value: 'Vata', label: 'Vata' },
  { value: 'Pitta', label: 'Pitta' },
  { value: 'Kapha', label: 'Kapha' },
];

export const DHATHU = [
  { value: 'Rasa Dhatu', label: 'Rasa Dhatu' },
  { value: 'Rakta Dhatu', label: 'Rakta Dhatu' },
  { value: 'Mamsa Dhatu', label: 'Mamsa Dhatu' },
  { value: 'Meda Dhatu', label: 'Meda Dhatu' },
  { value: 'Asthi Dhatu', label: 'Asthi Dhatu' },
  { value: 'Majja Dhatu', label: 'Majja Dhatu' },
  { value: 'Shukra Dhatu', label: 'Shukra Dhatu' },
];

export const PRAKRUTI = [
  { value: 'Vata', label: 'Vata' },
  { value: 'Pitta', label: 'Pitta' },
  { value: 'Kapha', label: 'Kapha' },
  { value: 'Vatapitha', label: 'Vatapitha' },
  { value: 'Vatakapha', label: 'Vatakapha' },
  { value: 'Pithakapha', label: 'Pithakapha' },
  { value: 'Samaprakruti', label: 'Samaprakruti' },
];

export const BHOOMIDESAM = [
  { value: 'Sadharanam', label: 'Sadharanam' },
  { value: 'Anoopam', label: 'Anoopam' },
  { value: 'Jangalam', label: 'Jangalam' },
];

export const VAYA = [
  { value: 'Balyam', label: 'Balyam' },
  { value: 'Youvanam', label: 'Youvanam' },
  { value: 'Madhyamam', label: 'Madhyamam' },
  { value: 'Vardhakyam', label: 'Vardhakyam' },
];

export const BALAM = [
  { value: 'Pravaram', label: 'Pravaram' },
  { value: 'Madhyamam', label: 'Madhyamam' },
  { value: 'Avaram', label: 'Avaram' },
];

export const SATWAM = [
  { value: 'Pravaram', label: 'Pravaram' },
  { value: 'Madhyamam', label: 'Madhyamam' },
  { value: 'Avaram', label: 'Avaram' },
];

export const ROGAVASTHA = [
  { value: 'Navam', label: 'Navam' },
  { value: 'Puranam', label: 'Puranam' },
];

export const RITUKALA = [
  { value: 'Shishir Ritu', label: 'Shishir Ritu' },
  { value: 'Vasant Ritu', label: 'Vasant Ritu' },
  { value: 'Grishma Ritu', label: 'Grishma Ritu' },
  { value: 'Varsha Ritu', label: 'Varsha Ritu' },
  { value: 'Sharad Ritu', label: 'Sharad Ritu' },
  { value: 'Hemant Ritu', label: 'Hemant Ritu' },
];

export const SATMYAM = [
  { value: 'Eka Rasam', label: 'Eka Rasam' },
  { value: 'Sarva Rasam', label: 'Sarva Rasam' },
  { value: 'Vyamisram', label: 'Vyamisram' },
];

export const ANALAM = [
  { value: 'Samagni', label: 'Samagni' },
  { value: 'Teekshagni', label: 'Teekshagni' },
  { value: 'Vishamagni', label: 'Vishamagni' },
  { value: 'Mandagni', label: 'Mandagni' },
];

export const JARANASAKTHI = [
  { value: 'Pravaram', label: 'Pravaram' },
  { value: 'Madhyamam', label: 'Madhyamam' },
  { value: 'Avaram', label: 'Avaram' },
];

export const ABYAVAHARANASAKTHI = [
  { value: 'Pravaram', label: 'Pravaram' },
  { value: 'Madhyamam', label: 'Madhyamam' },
  { value: 'Avaram', label: 'Avaram' },
];
