import { Helmet } from 'react-helmet-async';
import { useParams } from 'src/routes/hooks';
// sections
import { MedicalRecordView } from 'src/sections/emr/view';

// ----------------------------------------------------------------------

export default function MedicalRecordPage() {
  const params = useParams();
  const { uuid } = params;

  return (
    <>
      <Helmet>
        <title> Medical Record | Skymer Care</title>
      </Helmet>

      <MedicalRecordView uuid={`${uuid}`} />
    </>
  );
}
