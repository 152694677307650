// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/kAYnYYdib0aQPNKZpgJT6J/%5BPreview%5D-Minimal-Web.v5.0.0?type=design&node-id=0%3A1&t=Al4jScQq97Aly0Mn-1',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // BARCODE SCANNER
  scan: {
    barcode: `/scan/barcode`,
  },
  // PATIENTS
  patients: {
    new_patient: `/patients/new`,
    edit_patient: (uuid) => `/patients/${uuid}/edit`,
    patient_visit: (uuid) => `/patients/${uuid}/visit`,
    revisit: `/patients/revisit`,
    list: `/patients/list`,
  },
  // EMR - Electronic Medical Record
  emr: {
    root: `/emr`,
    screening: {
      awaiting: `/emr/screening/awaiting`,
      progress: `/emr/screening/progress`,
      completed: `/emr/screening/completed`,
    },
    record: (uuid) => `/emr/${uuid}/record`,
  },
  // DOCTORS
  doctors: {
    root: `/doctor`,
    list: `/doctors/list`,
    enquiry: `/doctors/enquiry`,
    new: `/doctors/new`,
    edit_doctor: (uuid) => `/doctors/${uuid}/edit_doctor`,
  },
  // MASTERS
  masters: {
    root: `/master`,
    departments: `/masters/departments`,
    payment_settings: `/masters/payment_settings`,
  },
  // REPORT
  report: {
    root: `/report`,
    op_registration_report: `/report/op_registration_report`,
    op_transaction_report: `/report/op_transaction_report`,
    op_statistics_report: `/report/op_statistics_report`,
    // op_yearly_register: `/report/op_yearly_register`,
  },
  // PRINT
  print: {
    op_bill: (uuid) => `/print/${uuid}/op_bill`,
  },
  // DASHBOARD
  dashboard: {
    root: '/',
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      // new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
  },
};
