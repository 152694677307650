import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
// utils
import { fDate } from 'src/utils/format-time';
import { fCurrency } from 'src/utils/format-number';
// _mock
import { INVOICE_STATUS_OPTIONS } from 'src/_mock';
// components
import Label from 'src/components/label';
import Scrollbar from 'src/components/scrollbar';
//
import { Age } from 'src/utils/calculator';
import ReportToolbar from './common/report-toolbar';
import OpStatisticsReportPDF from './op-statistics-report-pdf';

// ----------------------------------------------------------------------

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& td': {
    textAlign: 'right',
    borderBottom: 'none',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------

export default function OpStatisticsReportDetails({
  reportData,
  reportTitle,
  departmentData,
  flgReport,
}) {
  const onReset = () => {
    flgReport.onFalse();
  };

  const renderFooter = (
    <Grid container>
      <Grid xs={12} md={9} sx={{ py: 3 }}>
        <Typography variant="subtitle2">NOTES</Typography>

        <Typography variant="body2">
          This is a system-generated report. No signature required.
        </Typography>
      </Grid>

      <Grid xs={12} md={3} sx={{ py: 3, textAlign: 'right' }}>
        <Typography variant="subtitle2">Printed By</Typography>

        <Typography variant="body2">Reception</Typography>
      </Grid>
    </Grid>
  );

  const renderList = (
    <TableContainer sx={{ overflow: 'unset', mt: 5 }}>
      <Scrollbar>
        <Table size="small" sx={{ minWidth: 960 }}>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              {departmentData.map((dept, index) => (
                <TableCell key={index}>{dept}</TableCell>
              ))}
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.entries(reportData).map(([date, row], index) => (
              <TableRow key={index}>
                <TableCell sx={{ minWidth: 115 }}>{fDate(row.visit_date)}</TableCell>
                {departmentData.map((dept, deptIndex) => (
                  <TableCell key={deptIndex}>{row[dept] || 0}</TableCell>
                ))}
                <TableCell>{row.Total}</TableCell>
              </TableRow>
            ))}

            {/* {renderTotal} */}
          </TableBody>
        </Table>
      </Scrollbar>
    </TableContainer>
  );

  return (
    <>
      <ReportToolbar
        reportData={{ statisticsReportData: reportData, departmentData }}
        reportTitle={reportTitle}
        onReset={onReset}
        reportPDF={OpStatisticsReportPDF}
      />

      <Card sx={{ pt: 5, px: 5 }}>
        <Box rowGap={5} display="grid" alignItems="center">
          <Stack spacing={1} alignItems="center">
            <Typography variant="h3">
              Sree Narayana Institute of Ayurvedic Studies and Research - Hospital
            </Typography>
            <Typography variant="h6">Pangode</Typography>
          </Stack>
        </Box>

        <Box
          rowGap={5}
          display="grid"
          alignItems="center"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >
          <Stack spacing={1} sx={{ borderBottom: '1px solid' }}>
            <Typography>{reportTitle}</Typography>
          </Stack>
          <Stack
            spacing={1}
            alignItems={{
              xs: 'flex-start',
              md: 'flex-end',
            }}
            sx={{ borderBottom: '1px solid' }}
          >
            <Typography>
              <b>Report Date:</b> {fDate(new Date())}
            </Typography>
          </Stack>
        </Box>

        {renderList}

        <Divider sx={{ mt: 5, borderStyle: 'dashed' }} />

        {renderFooter}
      </Card>
    </>
  );
}

OpStatisticsReportDetails.propTypes = {
  reportData: PropTypes.array,
  reportTitle: PropTypes.string,
  departmentData: PropTypes.array,
  flgReport: PropTypes.object,
};
