import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { BlobProvider, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
// api
import { useGetPaymentCreditors } from 'src/api/payment_settings';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
//
import { fDate } from 'src/utils/format-time';

// ----------------------------------------------------------------------

export default function ReportToolbar({
  reportData,
  reportTitle,
  onReset,
  flgDepartment,
  reportPDF: Component,
}) {
  const router = useRouter();
  const { creditors, creditorsLoading, creditorsEmpty } = useGetPaymentCreditors();
  const view = useBoolean();

  const handlePrint = useCallback((url) => {
    const iframe = document.createElement('iframe');
    iframe.style.position = 'fixed';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = 'none';
    iframe.src = url;

    document.body.appendChild(iframe);
    iframe.onload = () => {
      iframe.contentWindow.print();
      iframe.onafterprint = () => {
        document.body.removeChild(iframe);
      };
    };
  }, []);

  return (
    <>
      <Stack
        spacing={3}
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'flex-end', sm: 'center' }}
        sx={{ mb: { xs: 3, md: 5 } }}
      >
        <Stack direction="row" spacing={1} flexGrow={1} sx={{ width: 1 }}>
          <Tooltip title="View">
            <IconButton onClick={view.onTrue}>
              <Iconify icon="solar:eye-bold" />
            </IconButton>
          </Tooltip>

          {/*  <PDFDownloadLink
            document={<Component reportTitle={reportTitle} reportData={reportData} />}
            fileName={`Op-Registration-Report ${fDate(new Date())}`}
            style={{ textDecoration: 'none' }}
            flgDepartment={flgDepartment}
          >
            {({ loading }) => (
              <Tooltip title="Download">
                <IconButton>
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <Iconify icon="eva:cloud-download-fill" />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </PDFDownloadLink>

          <BlobProvider
            document={
              <Component
                reportTitle={reportTitle}
                reportData={reportData}
                flgDepartment={flgDepartment}
              />
            }
          >
            {({ url, loading }) => (
              <Tooltip title="Print">
                <IconButton onClick={() => !loading && handlePrint(url)}>
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <Iconify icon="solar:printer-minimalistic-bold" />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </BlobProvider> */}
        </Stack>
        {/* <TextField
          fullWidth
          select
          label="Status"
          // value={currentStatus}
          // onChange={onChangeStatus}
          sx={{
            maxWidth: 160,
          }}
        >
          <MenuItem>Test</MenuItem>
        </TextField> */}
        <Button color="inherit" variant="outlined" size="small" onClick={onReset}>
          Reset
        </Button>
      </Stack>

      <Dialog fullScreen open={view.value}>
        <Box sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              p: 1.5,
            }}
          >
            <Button color="inherit" variant="contained" onClick={view.onFalse}>
              Close
            </Button>
          </DialogActions>

          <Box sx={{ flexGrow: 1, height: 1, overflow: 'hidden' }}>
            <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
              <Component
                reportTitle={reportTitle}
                reportData={reportData}
                flgDepartment={flgDepartment}
                creditors={creditors}
              />
            </PDFViewer>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

ReportToolbar.propTypes = {
  reportData: PropTypes.array,
  reportTitle: PropTypes.string,
  onReset: PropTypes.func,
  flgDepartment: PropTypes.bool,
  reportPDF: PropTypes.elementType.isRequired,
};
