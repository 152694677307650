import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
// routes
import { paths } from 'src/routes/paths';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// _mock

// components
import { PatientWorkflowProvider } from 'src/workflows/patient_workflow_provider';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useGetMedicalReport } from 'src/api/emr';
import { Age } from 'src/utils/calculator';
import ProfileCover from '../profile-cover';
//
import ScreeningView from './screening-view';
import { ScreeningAccountSkeleton } from '../screening/screening-account-skeleton';

// ----------------------------------------------------------------------

const TABS = [
  {
    value: 'screening',
    label: 'Screening',
    icon: <Iconify icon="solar:user-id-bold" width={24} />,
  },
  {
    value: 'assessment',
    label: 'Assessment',
    icon: <Iconify icon="solar:heart-bold" width={24} />,
  },
  {
    value: 'consultation',
    label: 'Consultation',
    icon: <Iconify icon="solar:stethoscope-outline" width={24} />,
  },
  {
    value: 'medication',
    label: 'Medication',
    icon: <Iconify icon="healthicons:medicine-mortar-outline" width={24} />,
  },
  {
    value: 'investigation',
    label: 'Investigation',
    icon: <Iconify icon="guidance:medical-laboratory" width={24} />,
  },
  {
    value: 'radiology',
    label: 'Radiology',
    icon: <Iconify icon="teenyicons:camera-outline" width={24} />,
  },
];

// ----------------------------------------------------------------------

export default function MedicalRecordView({ uuid }) {
  const settings = useSettingsContext();
  const { record, recordLoading, recordError } = useGetMedicalReport(uuid);
  const [recordData, setRecordData] = useState([]);
  const { user } = useMockedUser();

  const [currentTab, setCurrentTab] = useState('screening');

  const totalImages = 24;
  const [coverUrl, setCoverUrl] = useState('');
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const generatedCoverUrl = `/assets/background/cover_${randomIndex}.jpg`;
    setCoverUrl(generatedCoverUrl);
  }, []);

  useEffect(() => {
    if (record) {
      setRecordData(record);
    }
  }, [record]);

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <PatientWorkflowProvider patientVisitId={uuid}>
        <CustomBreadcrumbs
          heading="Electronic Medical Record"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Awaiting Screening List', href: paths.emr.screening.progress },
            { name: recordData?.patient?.patient_name },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card
          sx={{
            mb: 3,
            height: 180,
          }}
        >
          {recordLoading ? (
            <ScreeningAccountSkeleton />
          ) : (
            <ProfileCover
              gender={recordData?.patient?.gender}
              age={Age(recordData?.patient?.date_of_birth)}
              name={recordData?.patient?.patient_name?.toUpperCase()}
              medical_record_no={recordData?.patient?.medical_record_no}
              avatarUrl={
                recordData?.patient?.recommendation === '0'
                  ? '/assets/images/no_image_1.png'
                  : '/assets/images/no_image_2.png'
              }
              coverUrl={coverUrl}
            />
          )}
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            sx={{
              width: 1,
              bottom: 0,
              zIndex: 9,
              position: 'absolute',
              bgcolor: 'background.paper',
              [`& .${tabsClasses.flexContainer}`]: {
                pr: { md: 3 },
                justifyContent: {
                  sm: 'center',
                  md: 'flex-end',
                },
              },
            }}
          >
            {TABS.map((tab) => (
              <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
            ))}
          </Tabs>
        </Card>

        {currentTab === 'screening' && !recordLoading && <ScreeningView record={recordData} />}

        {/* {currentTab === 'followers' && <ProfileFollowers followers={_userFollowers} />}

      {currentTab === 'friends' && (
        <ProfileFriends
          friends={_userFriends}
          searchFriends={searchFriends}
          onSearchFriends={handleSearchFriends}
        />
      )}

      {currentTab === 'gallery' && <ProfileGallery gallery={_userGallery} />} */}
      </PatientWorkflowProvider>
    </Container>
  );
}
MedicalRecordView.propTypes = {
  uuid: PropTypes.string,
};
